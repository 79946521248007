var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { top: "", color: "primary", dark: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _vm.complete
                    ? _c(
                        "v-icon",
                        _vm._g(
                          _vm._b(
                            { staticClass: "success--text lighten-1" },
                            "v-icon",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v("mdi-check-bold")]
                      )
                    : _vm._e(),
                  _vm.expired && !_vm.complete
                    ? _c(
                        "v-icon",
                        _vm._g(
                          _vm._b(
                            { staticClass: "error--text text--darken-1" },
                            "v-icon",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v("mdi-timer-sand-full")]
                      )
                    : _vm._e(),
                  !_vm.complete && !_vm.expired
                    ? _c(
                        "v-icon",
                        _vm._g(
                          _vm._b(
                            { staticClass: "primary--text text--darken-2" },
                            "v-icon",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v("assignment_ind")]
                      )
                    : _vm._e()
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.label))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }