<template>
    <div>
        <v-tooltip top color="primary" dark>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="success--text lighten-1"
                    v-if="complete"
                    >mdi-check-bold</v-icon
                >
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="error--text text--darken-1"
                    v-if="expired && !complete"
                    >mdi-timer-sand-full</v-icon
                >
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="primary--text text--darken-2"
                    v-if="!complete && !expired"
                    >assignment_ind</v-icon
                >
            </template>
            <span>{{ label }}</span>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    name: "application-list-item-icon",
    components: {},
    props: {
        status: {
            type: String,
            required: true,
        },
        expired: {
            type: Boolean,
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
    },
    data() {
        return {};
    },
    computed: {
        complete() {
            return this.status === "Complete";
        },
        label() {
            return this.status === "Complete"
                ? "Application Complete"
                : this.expired
                ? "Application Expired"
                : "Application in Progress";
        },
    },
};
</script>

<style scoped>
</style>