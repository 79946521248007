var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list-item",
        [
          _c(
            "v-list-item-icon",
            [
              _c(
                "application-list-item-icon",
                _vm._b({}, "application-list-item-icon", _vm.item, false)
              )
            ],
            1
          ),
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                [
                  _vm.item.recent
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "error darken-1", label: "" }
                        },
                        [
                          _c("v-icon", { attrs: { left: "", small: "" } }, [
                            _vm._v("mdi-progress-alert")
                          ]),
                          _vm._v("new ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold text-uppercase subheading"
                    },
                    [_vm._v(" " + _vm._s(_vm.item.email) + " ")]
                  ),
                  _c("span", { staticClass: "grey--text" }, [_vm._v("|")]),
                  _c(
                    "span",
                    { staticClass: "grey--text text--darken-2 caption" },
                    [_vm._v(" " + _vm._s(_vm.item.created_at) + " ")]
                  ),
                  _c("span", { staticClass: "grey--text font-weight-normal" }, [
                    _vm._v("|")
                  ]),
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-bold caption grey--text text--darken-1"
                    },
                    [_vm._v(" ID: " + _vm._s(_vm.item.id))]
                  ),
                  _vm._v(" | "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-bold caption grey--text text--darken-1"
                    },
                    [_vm._v(" Group Code: " + _vm._s(_vm.meta.code))]
                  ),
                  _vm._v(" | "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-bold caption grey--text text--darken-1"
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "Edit Application",
                              query: _vm.item.params
                            }
                          }
                        },
                        [_vm._v("ORDER ID: " + _vm._s(_vm.item.order_id))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item-subtitle",
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "mr-1 text-uppercase pl-0",
                      attrs: { small: "", color: _vm.colors[_vm.item.status] }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v("mdi-alert-circle")
                      ]),
                      _vm._v(_vm._s(_vm.item.status))
                    ],
                    1
                  ),
                  _vm._v(" " + _vm._s(_vm.company.name) + " "),
                  _c("span", { staticClass: "grey--text" }, [_vm._v("|")]),
                  _vm._v(" " + _vm._s(_vm.property.name) + " | "),
                  _c("strong", [
                    _vm._v(
                      "Portland City Application: " +
                        _vm._s(
                          _vm.$yesNoFormat(
                            _vm.$_.get(
                              _vm.property,
                              "flags.portland_city_application",
                              false
                            )
                          )
                        )
                    )
                  ]),
                  _vm._v(
                    " | Cosigner: " +
                      _vm._s(_vm.$yesNoFormat(_vm.item.type === "Cosigner")) +
                      " | Non-applicant: " +
                      _vm._s(
                        _vm.$yesNoFormat(_vm.item.type === "Non Applicant")
                      ) +
                      " | Processing: " +
                      _vm._s(_vm.property.process_mode) +
                      " "
                  ),
                  _c("strong", [
                    _vm._v(" Source: " + _vm._s(_vm.meta.source) + " ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("application-list-item-actions", {
            attrs: { item: _vm.item, source: _vm.meta.source }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }