var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "elevation-4" },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "secondary", flat: "", dense: "", dark: "" } },
            [
              _c("v-icon", [_vm._v("assignment_ind")]),
              _c("v-toolbar-title", { staticClass: "ml-2 mr-2" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _vm.$_.has(_vm.pagination, "total")
                ? _c(
                    "v-chip",
                    {
                      staticClass: "caption",
                      attrs: { small: "", color: "secondary darken-2" }
                    },
                    [_vm._v(_vm._s(_vm.pagination.total))]
                  )
                : _vm._e(),
              false
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex justify-center",
                      staticStyle: { width: "80%" }
                    },
                    [
                      _c("app-search", {
                        staticClass: "ml-4",
                        attrs: {
                          "background-color": "secondary lighten-1",
                          search: _vm.search
                        },
                        on: {
                          "update:search": function($event) {
                            _vm.search = $event
                          },
                          enter: _vm.onSearchEnter,
                          clear: _vm.refresh
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.refresh } },
                [_c("v-icon", [_vm._v("mdi-refresh")])],
                1
              )
            ],
            1
          ),
          Object.keys(_vm.pagination).length > 0
            ? _c(
                "application-list-paginator",
                _vm._b(
                  {
                    attrs: {
                      per_page: _vm.per_page,
                      current_page: _vm.current_page
                    },
                    on: {
                      "update:per_page": function($event) {
                        _vm.per_page = $event
                      },
                      "update:current_page": function($event) {
                        _vm.current_page = $event
                      }
                    }
                  },
                  "application-list-paginator",
                  _vm.pagination,
                  false
                )
              )
            : _vm._e(),
          _c("v-divider"),
          _vm.loading
            ? _c("psi-content-loader", { attrs: { type: "circular" } })
            : _c(
                "div",
                { attrs: { id: "application-list-content" } },
                [
                  _vm._l(_vm.applications, function(ref, index) {
                    var property = ref.property
                    var company = ref.company
                    var meta = ref.meta
                    var applicants = ref.applicants
                    return [
                      _c(
                        "h3",
                        {
                          key: index,
                          staticClass:
                            "\n                        ml-6\n                        my-2\n                        primary--text\n                        text--darken-2 text-uppercase\n                    "
                        },
                        [
                          _vm._v(
                            " PROPERTY: " +
                              _vm._s(property.name) +
                              " | CODE: " +
                              _vm._s(meta.code) +
                              " "
                          )
                        ]
                      ),
                      _c("v-divider", {
                        key: "top-divider-" + index,
                        attrs: { inset: "" }
                      }),
                      _c("application-list", {
                        key: "application-list-" + index,
                        staticClass: "ml-6",
                        attrs: {
                          items: applicants,
                          property: property,
                          company: company,
                          meta: meta,
                          color: _vm.color
                        }
                      }),
                      index < _vm.applications.length - 1
                        ? _c("v-divider", {
                            key: "bottom-divider=" + index,
                            attrs: { inset: "" }
                          })
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }