var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { staticClass: "my-2", attrs: { flat: "", dense: "" } },
    [
      _c("h5", [_vm._v("Rows per page:")]),
      _c("v-select", {
        staticClass: "mx-4",
        staticStyle: { "max-width": "100px" },
        attrs: {
          width: "100",
          solo: "",
          dense: "",
          "hide-details": "",
          items: _vm.items,
          value: _vm.per_page
        },
        on: { input: _vm.changePerPage }
      }),
      _c("h5", { staticClass: "font-weight-light" }, [
        _vm._v(
          " " +
            _vm._s(_vm.from) +
            " - " +
            _vm._s(_vm.to) +
            " of " +
            _vm._s(_vm.total) +
            " "
        )
      ]),
      _c("v-spacer"),
      _c(
        "v-toolbar-items",
        [
          _c("v-pagination", {
            key: "" + _vm.per_page + _vm.total_pages,
            attrs: {
              value: _vm.current_page,
              length: _vm.total_pages,
              "total-visible": "10"
            },
            on: { input: _vm.changePage }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }