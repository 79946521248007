<template>
    <v-list-item-action>
        <v-row align-center>
            <psi-action-drop-down
                :items="filteredMenuItems"
                heading="Application Actions"
                icon-class="primary--text"
                icon="mdi-playlist-check"
                @action="handleAction"
            ></psi-action-drop-down>
        </v-row>
    </v-list-item-action>
</template>
<script>
// import api from "@components/application/api";
// import applicantApi from "@components/application/api/applicant";

export default {
    name: "application-list-item-actions",
    components: {},
    props: {
        item: {
            type: Object,
            required: true,
        },
        source: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            menuItems: this.$config("applications.listingMenuItemActions"),
        };
    },
    computed: {
        filteredMenuItems() {
            return this.menuItems.filter((item) => {
                return item.sources.includes(this.source);
            });
        },
    },
    methods: {
        handleAction(item) {
            console.log(item.title);
            // The action property determines the function we need to call
            switch (item.slug) {
                case "delete-application":
                    // this.deleteApplication();
                    break;
                case "add-applicant":
                    // this.addApplicant("Applicant");
                    break;
                case "add-cosigner":
                    // this.addApplicant("Cosigner");
                    break;
                case "submit-application":
                    // this.resubmitApplication();
                    break;
                case "copy-application":
                    // this.$copyText(JSON.stringify(this.item.app));
                    break;
                case "complete-as-applicant":
                    // this.completeApplication("Applicant");
                    break;
                case "complete-as-cosigner":
                    // this.completeApplication("Cosigner");
                    break;
                case "complete-as-non-applicant":
                    // this.completeApplication("Non-Applicant");
                    break;
            }
        },
        /*
        async deleteApplication() {
            // Use PSI Confirm Dialog

            const res = await this.$confirm(
                `Do you want to delete the application for applicant at email address: <strong> ${this.item_email}</strong>?`,
                { title: "Delete Application" }
            );
            if (res) {
                // TOOD: add the delete Application call in the API
                this.$store.dispatch("deleteApplication", {
                    applicant_id: this.item.applicant_id,
                });
            }
        },
        */
        /*
        addApplicant(applicantType) {
            this.$store.dispatch("addApplication", {
                applicant_id: this.get(this.item, "app.applicant_id", 0),
                applicantType,
            });
        },
        */
        /*
        async resubmitApplication() {
            // TODO: Move direct API call to Vuex Store
            await api.resubmitApplication(this.item.app);
        },
        */
        /*
        async completeApplication(applicantType) {
            // TODO: Move direct API call to Vuex Store
            let app = this.item.app;
            const applicant = applicantApi.getApplicant(
                app.applicant_id,
                applicantType
            );
            app.applicants = [applicant];
            if (applicantType != "Non-Applicant") {
                app.payment = api.createPayment(
                    this.item.app.applicant_email,
                    parseFloat(this.item.app.application.product.price)
                );
            }

            if (applicantType == "Non-Applicant") {
                app = await api.setApplicationNonApplicant(app);
            } else {
                // save the application before complete, complete doesn't save
                await api.saveApplication(app);
            }
            // complete the saved application
            await api.completeApplication(app);
            // Get application status
            this.$store.dispatch("getApplications");
        },
        */
    },
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
};
</script>

<style scoped>
</style>