var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "400px" } },
    [
      _c("v-text-field", {
        staticClass: "blue--text hidden-sm-and-down",
        attrs: {
          value: _vm.search,
          solo: "",
          flat: "",
          "hide-details": "",
          clearable: "",
          dark: "",
          label: "Search",
          color: "white",
          "background-color": _vm.backgroundColor,
          "prepend-inner-icon": "search"
        },
        on: {
          input: function($event) {
            return _vm.$emit("update:search", $event)
          },
          change: function($event) {
            return _vm.$emit("change", $event)
          },
          keydown: _vm.onKeydown,
          "click:clear": function($event) {
            return _vm.$emit("clear")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }