<template>
    <v-list three-line>
        <template v-for="(item, index) in items">
            <application-list-item
                :key="`list-${index}`"
                :item="item"
                :property="property"
                :company="company"
                :meta="meta"
                :color="color"
            ></application-list-item>
            <v-divider
                inset
                v-if="index < items.length - 1"
                :key="`div-${index}`"
            ></v-divider>
        </template>
    </v-list>
</template>

<script>
import ApplicationListItem from "./ApplicationListItem";
export default {
    components: {
        ApplicationListItem,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        meta: {
            type: Object,
            required: true,
        },
        property: {
            type: Object,
            required: true,
        },
        company: {
            type: Object,
            required: true,
        },
        color: {
            type: String,
            default: "error",
        },
    },
};
</script>

<style>
</style>
