var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { "three-line": "" } },
    [
      _vm._l(_vm.items, function(item, index) {
        return [
          _c("application-list-item", {
            key: "list-" + index,
            attrs: {
              item: item,
              property: _vm.property,
              company: _vm.company,
              meta: _vm.meta,
              color: _vm.color
            }
          }),
          index < _vm.items.length - 1
            ? _c("v-divider", { key: "div-" + index, attrs: { inset: "" } })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }