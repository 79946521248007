<template>
    <div style="width: 400px">
        <v-text-field
            :value="search"
            solo
            flat
            hide-details
            clearable
            dark
            label="Search"
            color="white"
            :background-color="backgroundColor"
            class="blue--text hidden-sm-and-down"
            prepend-inner-icon="search"
            @input="$emit('update:search', $event)"
            @change="$emit('change', $event)"
            @keydown="onKeydown"
            @click:clear="$emit('clear')"
        ></v-text-field>
    </div>
</template>
<script>
export default {
    name: "app-search",
    components: {},
    props: {
        backgroundColor: {
            type: String,
            required: false,
            default: "primary lighten-1",
        },
        search: {
            type: String,
            required: false,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        onKeydown(event) {
            if (event.code === "Enter") {
                this.$emit("enter");
            }
        },
    },
};
</script>

<style scoped>
</style>