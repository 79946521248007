<template>
    <v-toolbar flat dense class="my-2">
        <h5>Rows per page:</h5>
        <v-select
            width="100"
            solo
            dense
            hide-details
            class="mx-4"
            :items="items"
            :value="per_page"
            @input="changePerPage"
            style="max-width: 100px"
        ></v-select>
        <h5 class="font-weight-light">
            {{ from }} - {{ to }} of
            {{ total }}
        </h5>
        <v-spacer></v-spacer>
        <v-toolbar-items>
            <!-- Force re-rendering on key change when per page and total pages change -->
            <v-pagination
                :key="`${per_page}${total_pages}`"
                :value="current_page"
                :length="total_pages"
                @input="changePage"
                total-visible="10"
            ></v-pagination>
        </v-toolbar-items>
    </v-toolbar>
</template>
<script>
export default {
    name: "application-list-paginator",
    components: {},
    props: {
        to: {
            type: Number,
            required: true,
        },
        from: {
            type: Number,
            required: true,
        },
        current_page: {
            type: Number,
            required: true,
        },
        total_pages: {
            type: Number,
            required: true,
        },
        total: {
            type: Number,
            required: true,
        },
        per_page: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            items: this.$config("applications.pagination.perPageOptions"),
        };
    },
    methods: {
        changePerPage(perPage) {
            this.$emit("update:per_page", perPage);
        },
        changePage(page) {
            this.$emit("update:current_page", page);
        },
    },
    mounted() {},
};
</script>

<style scoped>
</style>