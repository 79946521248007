<template>
    <div>
        <v-list-item>
            <v-list-item-icon>
                <application-list-item-icon
                    v-bind="item"
                ></application-list-item-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    <v-chip
                        color="error darken-1"
                        class="mr-2"
                        label
                        v-if="item.recent"
                    >
                        <v-icon left small>mdi-progress-alert</v-icon>new
                    </v-chip>
                    <span class="font-weight-bold text-uppercase subheading">
                        {{ item.email }}
                    </span>
                    <span class="grey--text">|</span>
                    <span class="grey--text text--darken-2 caption">
                        {{ item.created_at }}
                    </span>
                    <span class="grey--text font-weight-normal">|</span>
                    <span
                        class="font-weight-bold caption grey--text text--darken-1"
                    >
                        ID: {{ item.id }}</span
                    >
                    |
                    <span
                        class="font-weight-bold caption grey--text text--darken-1"
                    >
                        Group Code:
                        {{ meta.code }}</span
                    >
                    |

                    <span
                        class="font-weight-bold caption grey--text text--darken-1"
                    >
                        <!-- v-if="meta.source == 'Portal'" -->
                        <router-link
                            :to="{
                                name: 'Edit Application',
                                query: item.params,
                            }"
                            >ORDER ID: {{ item.order_id }}</router-link
                        >
                    </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                    <v-chip
                        small
                        class="mr-1 text-uppercase pl-0"
                        :color="colors[item.status]"
                        ><v-icon class="mr-1">mdi-alert-circle</v-icon
                        >{{ item.status }}</v-chip
                    >
                    {{ company.name }}
                    <span class="grey--text">|</span>
                    {{ property.name }} |
                    <strong
                        >Portland City Application:
                        {{
                            $yesNoFormat(
                                $_.get(
                                    property,
                                    "flags.portland_city_application",
                                    false
                                )
                            )
                        }}</strong
                    >
                    | Cosigner:
                    {{ $yesNoFormat(item.type === "Cosigner") }} |
                    Non-applicant:
                    {{ $yesNoFormat(item.type === "Non Applicant") }}
                    | Processing:
                    {{ property.process_mode }}
                    <strong> Source: {{ meta.source }} </strong>
                </v-list-item-subtitle>
                <!-- <v-list-item-sub-title>{{ item.search }} </v-list-item-sub-title> -->
            </v-list-item-content>
            <application-list-item-actions
                :item="item"
                :source="meta.source"
            ></application-list-item-actions>
        </v-list-item>
    </div>
</template>

<script>
import ApplicationListItemActions from "./ApplicationListItemActions";
import ApplicationListItemIcon from "./ApplicationListItemIcon";
export default {
    components: {
        ApplicationListItemActions,
        ApplicationListItemIcon,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        property: {
            type: Object,
            required: true,
        },
        meta: {
            type: Object,
            required: true,
        },
        company: {
            type: Object,
            required: true,
        },
        color: {
            type: String,
            default: "error",
        },
        code: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            application: [],
            colors: {
                Complete: "primary",
                Pending: "warning darken-2",
                Paid: "secondary",
                "In Progress": "warning darken-1",
                Submitting: "error darken-2",
            },
        };
    },
    watch: {
        dialog() {
            this.completeType = false;
        },
        item: {
            immediate: true,
            deep: true,
            handler(value) {
                // eslint-disable-next-line no-prototype-builtins
                if (value.hasOwnProperty("app")) {
                    return (this.application = value.app.application);
                }
            },
        },
    },
    methods: {},
};
</script>

<style>
</style>
