<template>
    <div>
        <!-- <psi-content-loader v-if="loading"></psi-content-loader> -->
        <v-card class="elevation-4">
            <v-toolbar color="secondary" flat dense dark>
                <v-icon>assignment_ind</v-icon>
                <v-toolbar-title class="ml-2 mr-2">{{ title }}</v-toolbar-title>
                <v-chip
                    small
                    color="secondary darken-2"
                    class="caption"
                    v-if="$_.has(pagination, 'total')"
                    >{{ pagination.total }}</v-chip
                >
                <div
                    class="d-flex justify-center"
                    style="width: 80%"
                    v-if="false"
                >
                    <app-search
                        class="ml-4"
                        background-color="secondary lighten-1"
                        :search.sync="search"
                        @enter="onSearchEnter"
                        @clear="refresh"
                    ></app-search>
                </div>
                <v-spacer></v-spacer>
                <v-btn icon @click="refresh">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar>
            <application-list-paginator
                v-if="Object.keys(pagination).length > 0"
                :per_page.sync="per_page"
                :current_page.sync="current_page"
                v-bind="pagination"
            ></application-list-paginator>
            <v-divider></v-divider>
            <psi-content-loader
                v-if="loading"
                type="circular"
            ></psi-content-loader>
            <div v-else id="application-list-content">
                <template
                    v-for="(
                        { property, company, meta, applicants }, index
                    ) in applications"
                >
                    <h3
                        :key="index"
                        class="
                            ml-6
                            my-2
                            primary--text
                            text--darken-2 text-uppercase
                        "
                    >
                        PROPERTY: {{ property.name }} | CODE: {{ meta.code }}
                    </h3>
                    <v-divider :key="`top-divider-${index}`" inset></v-divider>
                    <application-list
                        :key="`application-list-${index}`"
                        :items="applicants"
                        :property="property"
                        :company="company"
                        :meta="meta"
                        class="ml-6"
                        :color="color"
                    ></application-list>
                    <v-divider
                        :key="`bottom-divider=${index}`"
                        v-if="index < applications.length - 1"
                        inset
                    ></v-divider>
                </template>
            </div>
        </v-card>
    </div>
</template>

<script>
import ApplicationList from "./ApplicationList";
import { mapGetters, mapActions } from "vuex";
import ApplicationListPaginator from "./ApplicationListPaginator.vue";
import AppSearch from "@components/search/components/AppSearch.vue";

export default {
    components: {
        ApplicationList,
        ApplicationListPaginator,
        AppSearch,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: "error",
        },
    },
    data() {
        return {
            per_page: this.$config("applications.pagination.perPage"),
            current_page: 1, // start on page 1
            search: "",
        };
    },
    computed: {
        ...mapGetters("Applications", [
            "applications",
            "pagination",
            "loading",
        ]),
    },
    watch: {
        current_page() {
            this.refresh();
        },
        per_page() {
            // start at page 1 when changing per page counts
            this.current_page = 1;
            this.refresh();
        },
    },
    methods: {
        ...mapActions("Applications", [
            "getApplications",
            "searchApplications",
        ]),
        refresh() {
            this.getApplications({
                page: this.current_page,
                perPage: this.per_page,
            });
        },
        onSearchEnter() {
            this.searchApplications(this.search);
        },
    },
    mounted() {
        this.refresh();
    },
};
</script>

<style>
</style>
