var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item-action",
    [
      _c(
        "v-row",
        { attrs: { "align-center": "" } },
        [
          _c("psi-action-drop-down", {
            attrs: {
              items: _vm.filteredMenuItems,
              heading: "Application Actions",
              "icon-class": "primary--text",
              icon: "mdi-playlist-check"
            },
            on: { action: _vm.handleAction }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }